<template>
  <validation-observer ref="validateCourseDetails" v-slot="{ invalid }" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Course Details</h5>
        <small class="text-muted"> Enter The Course Details. </small>
      </b-col>
      <b-col md="4">
        <b-form-group label="Name" label-for="name">
          <validation-provider #default="{ errors }" name="name" rules="required">
            <b-form-input
              id="name"
              v-model="courseData.name"
              :state="errors.length > 0 ? false : null"
              placeholder="CompTIA Security+"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Subject" label-for="subject">
          <validation-provider #default="{ errors }" name="subject" rules="required">
            <v-select
              class="w-100"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="courseData.subject"
              :options="subjectSuggestions"
              :clearable="false"
              @search="searchForSubject"
              @input="selectSubject"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Instructor" label-for="instructor">
          <validation-provider #default="{ errors }" name="instructor">
            <v-select
              class="w-100"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="courseData.instructor"
              :options="instructorSuggestions"
              :clearable="true"
              @search="searchForInstructors"
              @input="(val) => (courseData.instructor = val)"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Visibility" label-for="visibility">
          <validation-provider #default="{ errors }" name="visibility" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="courseData.visibility"
              :options="visibilityOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (courseData.visibility = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Status" label-for="status">
          <validation-provider #default="{ errors }" name="status" rules="required">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="courseData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              @input="(val) => (courseData.status = val)"
              class="w-100"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <hr />

      <b-table :fields="classesFields" :items="course.classes" :responsive="true" sort-by="date" :sort-desc="false">
        <!-- Column: Date of Class -->
        <template #cell(date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.date).format('MMMM Do YYYY (dddd)') }}
            </span>
          </div>
        </template>

        <!-- Column: Live Classes -->
        <template #cell(time)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ moment(data.item.timeStart, 'HH:mm:ss').format('hh:mm A') }}
              -
              {{ moment(data.item.timeEnd, 'HH:mm:ss').format('hh:mm A') }}
            </span>
          </div>
        </template>

        <!-- Column: Visibility -->
        <template #cell(type)="data">
          <b-badge pill :variant="`light-${data.item.type === 'class' ? 'success' : 'warning'}`" class="text-capitalize">
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(location)="data">
          <b-badge
            pill
            :variant="`light-${data.item.location === 'virtual' ? 'primary' : 'secondary'}`"
            class="text-capitalize"
          >
            {{ data.item.location }}
          </b-badge>
        </template>

        <!-- Column: Visibility -->
        <template #cell(visibility)="data">
          <b-badge pill :variant="`light-${resolveClassVisibilityVariant(data.item.visibility)}`" class="text-capitalize">
            {{ data.item.visibility }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveClassStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="startEditClass(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="startDeleteClass(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-row>

    <b-row>
      <b-col md="12">
        <hr />

        <b-button @click="discardChanges" variant="outline-secondary"> Discard </b-button>

        <b-button
          :disabled="invalid || !courseData.classes || courseData.classes.length === 0"
          @click="saveCourse"
          variant="primary"
          class="float-right"
        >
          {{ courseData.isNew ? 'Create Course' : 'Save Changes' }}
        </b-button>
        <b-button @click="addNewClasses" variant="outline-primary" class="float-right mr-1">Add Classes</b-button>
      </b-col>
    </b-row>

    <b-modal
      ref="modalClassEdit"
      cancel-variant="outline-secondary"
      ok-title="Save Changes"
      cancel-title="Discard"
      centered
      title="Edit Existing Class"
      @ok="saveClassEdit"
      @hidden="cancelClassEdit"
    >
      <b-row>
        <b-col cols="12" class="mb-2">
          <h5 class="mb-0">Class Editor</h5>
          <small class="text-muted">Update The Details Of The Class</small>
        </b-col>

        <b-col md="12">
          <b-form-group label="Date" label-for="class-date">
            <validation-provider #default="{ errors }" name="Date" vid="Date">
              <flat-pickr
                id="class-date"
                class="w-100 form-control"
                :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
                v-model="classDataLocal.date"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider #default="{ errors }" name="Start Time" rules="required">
            <b-form-group label="Start Time" label-for="class-start-time">
              <b-form-timepicker v-model="classDataLocal.timeStart" locale="en" input-id="class-start-time" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider #default="{ errors }" name="End Time" rules="required">
            <b-form-group label="End Time" label-for="class-end-time">
              <b-form-timepicker v-model="classDataLocal.timeEnd" locale="en" input-id="class-end-time" />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <b-form-group label="Type" label-for="type">
            <validation-provider #default="{ errors }" name="type" rules="required">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="classDataLocal.type"
                :options="classTypeOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                @input="(val) => (classDataLocal.type = val)"
                class="w-100"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Location" label-for="location">
            <validation-provider #default="{ errors }" name="location" rules="required">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="classDataLocal.location"
                :options="classLocationOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                @input="(val) => (classDataLocal.location = val)"
                class="w-100"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Visibility" label-for="visibility">
            <validation-provider #default="{ errors }" name="visibility" rules="required">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="classDataLocal.visibility"
                :options="classVisibilityOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                @input="(val) => (classDataLocal.visibility = val)"
                class="w-100"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Status" label-for="status">
            <validation-provider #default="{ errors }" name="status" rules="required">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="classDataLocal.status"
                :options="classStatusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                @input="(val) => (classDataLocal.status = val)"
                class="w-100"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';

import {
  BRow,
  BButton,
  BCol,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BTable,
  BBadge,
  BModal,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import useCoursesList from '@/views/apps/roster/courses-list/useCoursesList';
import { onUnmounted, ref, watch } from '@vue/composition-api';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import userStoreModule from '@/views/apps/user/userStoreModule';
import useClassesList from '@/views/apps/roster/classes-list/useClassesList';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'CourseDetailsTab',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BDropdown,
    BDropdownItem,
    BButton,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BBadge,
    BModal,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTimepicker,
    flatPickr,
  },
  props: {
    session: {
      type: Object,
    },
    course: {
      type: Object,
    },
    courses: {
      type: Array,
    },
    subject: {
      type: Object,
    },
    stage: { type: Number },
  },
  data: () => {
    return {
      classesFields: [
        { key: 'date', label: 'Date', sortable: true },
        { key: 'time', label: 'Time' },
        { key: 'type', label: 'Class Type' },
        { key: 'location', label: 'Location' },
        { key: 'visibility', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],

      previousName: undefined,

      defaultCourse: {
        name: undefined,
        subject: undefined,
        instructor: undefined,
        classes: [],
        visibility: 'public',
        status: 'created',
      },

      //

      classTypeOptions: [
        { label: 'Class', value: 'class' },
        { label: 'Lab', value: 'lab' },
      ],
      classLocationOptions: [
        { label: 'Physical', value: 'physical' },
        { label: 'Virtual', value: 'virtual' },
      ],
      classStatusOptions: [
        { label: 'Created', value: 'created' },
        { label: 'Waiting', value: 'waiting' },
        { label: 'Live', value: 'live' },
        { label: 'Completed', value: 'completed' },
      ],
      classVisibilityOptions: [
        { label: 'Internal', value: 'internal' },
        { label: 'Public', value: 'public' },
      ],
    };
  },
  methods: {
    addNewClasses() {
      this.$emit('update:course', { isNew: true, ...this.courseData });
      this.$emit('update:stage', 2);
    },
    startEditClass(clazz) {
      this.classData = clazz;
      this.classDataLocal = { ...clazz };

      this.$refs.modalClassEdit.show();
    },
    saveClassEdit() {
      console.log(`saving class`, this.classDataLocal);

      const classes = this.courseData.classes.filter((k) => k !== this.classData).concat(this.classDataLocal);

      console.log(`updating classes`, classes);

      this.$emit('update:course', { ...this.courseData, classes });
    },
    cancelClassEdit() {},
    startDeleteClass(clazz) {
      const classes = this.courseData.classes.filter((k) => k !== clazz);
      this.$emit('update:course', { ...clazz, classes });
    },
    discardChanges() {
      if (!this.courseData.isNew) {
        this.$emit('update:courses', [...this.courses.concat(this.course)]);
      }

      this.$emit('update:stage', 0);
    },
    saveCourse() {
      console.log(`saving course data AA`, this.courseData);
      return new Promise((resolve, reject) => {
        this.$refs.validateCourseDetails.validate().then((success) => {
          if (success) {
            delete this.courseData.isNew;
            console.log(`saving course data`, this.courseData);

            this.$emit('update:courses', [...this.courses.concat(this.courseData)]);
            this.$emit('update:course', JSON.parse(JSON.stringify(this.defaultCourse)));
            this.$emit('update:stage', 0);
            return resolve(true);
          }

          return reject();
        });
      });
    },
    selectSubject(subject) {
      console.log(`subf`, this.courseData, subject);
      this.courseData.subject = subject;

      if (!this.courseData.name || this.previousName === this.courseData.name) {
        this.previousName = subject.label;
        this.courseData.name = subject.label;
      }
    },
    async searchForSubject(search) {
      const response = await this.$store.dispatch('app-roster/fetchSubjects', { search });
      const subjects = response.data.results;

      this.subjectSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },
    async searchForInstructors(search) {
      const response = await this.$store.dispatch('app-user/fetchUsers', { search });
      const subjects = response.data.results;

      this.instructorSuggestions = subjects.map((p) => {
        let { id, name } = p;
        return { value: id, label: name };
      });
    },
  },
  watch: {
    course() {
      this.courseData = { ...this.course };
    },
  },
  mounted() {
    console.log(`editing based course`, this.course);
    this.searchForSubject('');
    this.searchForInstructors('');
  },
  setup(props, { emit }) {
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';
    const USER_APP_STORE_MODULE_NAME = 'app-user';

    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const courseData = ref({ ...props.course });

    if (props.subject) {
      const { subject } = props;
      courseData.value.name = subject.name;
      courseData.value.subject = { value: subject.id, label: subject.name };
    }

    const classData = ref({});
    const classLocalData = ref({});

    // watch([courseData], () => emit('update:course', courseData.value));

    const subjectSuggestions = ref([]);
    const instructorSuggestions = ref([]);

    const { statusOptions, visibilityOptions } = useCoursesList();

    const {
      // UI
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,
    } = useClassesList();

    return {
      courseData,
      classData,

      subjectSuggestions,
      instructorSuggestions,

      statusOptions,
      visibilityOptions,

      //
      moment,
      resolveClassVisibilityVariant,
      resolveClassStatusVariant,
    };
  },
};
</script>

<style scoped></style>
