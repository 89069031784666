var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validateCourseDetails",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Class Builder")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter The Details For The Classes To Create.")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date","label-for":"class-date"}},[_c('validation-provider',{attrs:{"name":"Date","vid":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"w-100 form-control",attrs:{"id":"report-data-date-range","config":{ mode: 'multiple', altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' },"value":_vm.classData.$dates},on:{"on-change":_vm.selectDates}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Time","label-for":"class-start-time"}},[_c('b-form-timepicker',{attrs:{"locale":"en","input-id":"class-start-time"},model:{value:(_vm.classData.timeStart),callback:function ($$v) {_vm.$set(_vm.classData, "timeStart", $$v)},expression:"classData.timeStart"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Time","label-for":"class-end-time"}},[_c('b-form-timepicker',{attrs:{"locale":"en","input-id":"class-end-time"},model:{value:(_vm.classData.timeEnd),callback:function ($$v) {_vm.$set(_vm.classData, "timeEnd", $$v)},expression:"classData.timeEnd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.classData.type,"options":_vm.classTypeOptions,"reduce":function (val) { return val.value; },"clearable":false},on:{"input":function (val) { return (_vm.classData.type = val); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Location","label-for":"location"}},[_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.classData.location,"options":_vm.classLocationOptions,"reduce":function (val) { return val.value; },"clearable":false},on:{"input":function (val) { return (_vm.classData.location = val); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Visibility","label-for":"visibility"}},[_c('validation-provider',{attrs:{"name":"visibility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.classData.visibility,"options":_vm.classVisibilityOptions,"reduce":function (val) { return val.value; },"clearable":false},on:{"input":function (val) { return (_vm.classData.visibility = val); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.classData.status,"options":_vm.classStatusOptions,"reduce":function (val) { return val.value; },"clearable":false},on:{"input":function (val) { return (_vm.classData.status = val); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('hr'),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.discardChanges}},[_vm._v(" Discard ")]),_c('b-button',{staticClass:"float-right",attrs:{"disabled":invalid,"variant":"primary"},on:{"click":_vm.saveCourse}},[_vm._v(" "+_vm._s(_vm.classData.isNew ? 'Add Classes' : 'Save Changes')+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }