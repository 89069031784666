<template>
  <validation-observer ref="accountRules" tag="form">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Session Summary</h5>
        <small class="text-muted"> Review the final creation details </small>
      </b-col>
      <b-col md="4">
        <b-form-group label="Name" label-for="name">
          <b-form-input id="name" v-model="sessionData.name" readonly />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Visibility" label-for="visibility">
          <validation-provider #default="{ errors }" name="visibility" rules="required">
            <b-form-input id="visibility" v-model="sessionData.visibility" readonly />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Status" label-for="status">
          <validation-provider #default="{ errors }" name="status" rules="required">
            <b-form-input id="status" v-model="sessionData.status" readonly />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <hr />

      <b-table :fields="coursesFields" :items="courses">
        <!-- A custom formatted column -->
        <template #cell(Popularity)="data">
          <b-progress :value="data.value.value" max="100" :variant="data.value.variant" striped />
        </template>

        <template #cell(order_status)="data">
          <b-badge pill :variant="data.value.variant">
            {{ data.value.status }}
          </b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(subject)="data">
          <span v-if="data.value && data.value.label">{{ data.value.label }}</span>
          <span v-else>No Subject Assigned</span>
        </template>

        <!-- A virtual composite column -->
        <template #cell(instructor)="data">
          <span v-if="data.value && data.value.label">{{ data.value.label }}</span>
          <span v-else>No Instructor Assigned</span>
        </template>

        <!-- A virtual composite column -->
        <template #cell(profiles)="data"> {{ data.value.length }} total </template>

        <!-- A virtual composite column -->
        <template #cell(classes)="data"> {{ data.value.length }} total </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="startEditCourse(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="startDeleteCourse(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-row>

    <b-row>
      <b-col md="12">
        <hr />

        <b-button :disabled="!isReady" @click="doResourceCreation" variant="primary" class="float-right">
          Create Resources
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import {
  BRow,
  BButton,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import useSessionsList from '@/views/apps/roster/sessions-list/useSessionsList';
import { onUnmounted, ref, watch } from '@vue/composition-api';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  name: 'SessionSummaryTab',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BButton,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
  },
  props: {
    pathway: {
      type: Object,
    },
    session: {
      type: Object,
    },
    course: {
      type: Object,
    },
    courses: {
      type: Array,
    },
    stage: { type: Number },
  },
  data: () => {
    return {
      coursesFields: [
        { key: 'name', label: 'Name' },
        { key: 'subject', label: 'Subject' },
        { key: 'instructor', label: 'Instructor' },
        { key: 'classes', label: 'Classes' },
        { key: 'actions', label: 'Actions' },
      ],
    };
  },
  computed: {
    isReady() {
      return this.session.name && this.courses.length > 0;
    },
  },
  methods: {
    addNewCourse() {
      this.$emit('update:course', { isNew: true, visibility: this.sessionData.visibility, status: this.sessionData.status });
      this.$emit('update:stage', 1);
    },
    startEditCourse(course) {
      const modified = this.courses.filter((k) => k !== course);

      this.$emit('update:courses', [...modified]);

      this.$emit('update:course', { ...course });
      this.$emit('update:stage', 1);
    },
    startDeleteCourse(course) {
      this.$emit('update:courses', this.courses.splice(this.courses.indexOf(course)));
    },

    async doResourceCreation() {
      let { session, courses } = this;

      let courseIds = [];

      const isSoloCourse = courses.length === 1;

      for (const course of courses) {
        const classes = course.classes;
        delete course.classes;

        if (isSoloCourse) {
          course.createParent = false;
        }

        course.subject = course.subject.value;

        if (course.instructor) {
          course.instructor = course.instructor.value;
        }

        let classPromises = Promise.all(
          classes.map(async (clazz) => {
            const response = await this.$store.dispatch('app-roster/addClass', { classData: clazz });

            console.log(response);

            if (response.status === 201) {
              return response.data;
            }

            return undefined;
          })
        );

        const classIds = (await classPromises).map((clazz) => clazz.id);

        course.classes = classIds;

        console.log(`course payload`, course);

        const response = await this.$store.dispatch('app-roster/addCourse', { courseData: course });

        if (response.status === 201) {
          console.log(`created course`, response.data);
          courseIds.push(response.data.id);
        }
      }

      console.log(`created all classes & courses, final courseId list`, courseIds);

      session.courses = courseIds;

      const response = await this.$store.dispatch('app-roster/addSession', { sessionData: session });

      if (response.status === 201) {
        const session = response.data;

        console.log(`final session created!`, response.data);

        if (session.id) {
          if (this.pathway && this.pathway.id) {
            await this.$store
              .dispatch('app-roster/addSessionToPathway', {
                pathwayId: this.pathway.id,
                sessionId: session.id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Added session to pathway',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                });
              })
              .catch((error) => {
                const { response } = error;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed to add session to pathway',
                    text: response ? response.data.message : error.message,
                    icon: 'EditIcon',
                    variant: 'error',
                  },
                });
              });
          }

          this.$router.push({ name: 'apps-roster-session-view', params: { id: session.id } });
        }
      }
    },
  },
  setup(props) {
    const PROFILE_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, rosterStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    const sessionData = ref({ ...props.session });

    const { statusOptions, visibilityOptions } = useSessionsList();

    return {
      sessionData,

      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style scoped>
.dark-layout input.form-control:disabled,
.dark-layout input.form-control[readonly='readonly'] {
  opacity: 1 !important;
}
</style>
